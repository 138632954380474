import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import AppRouter from './routes';
import AuthenticationGate from './routes/AuthenticationGate';
import store from './store';

const queryClient = new QueryClient();

function VisionfulApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthenticationGate>
          <AppRouter />
        </AuthenticationGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default VisionfulApp;
