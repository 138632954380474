import styled from "styled-components";
import { Button as AntdButton } from 'antd';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #F0F2F5;
`;

export const NumberStatus = styled.h1`
  font-size: 128px;
  padding: 0;
  margin: 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Text = styled.span`
  padding: 0;
  margin: 0;
`;

export const Button = styled(AntdButton)`
  border-radius: 8px;
`;
