import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

import { IFirabseConfig } from './type';

const firebaseConfig: IFirabseConfig = {
  apiKey: 'AIzaSyBBnaUrUSzUnZLAP1IBOxVPtSRNrkpAMiE',
  authDomain: 'cordon-counts.firebaseapp.com',
  databaseURL: 'https://cordon-counts.firebaseio.com',
  projectId: 'cordon-counts',
  storageBucket: 'cordon-counts.appspot.com',
  messagingSenderId: '150207293888',
  appId: '1:150207293888:web:ebd96c205d75170109c025',
  measurementId: 'G-F1XK75ZJ73',
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
