import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import {
  signInWithEmailAndPassword,
  User,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from 'firebase/auth';

import { auth } from '../../../config/firebase-config';
import { AppUser } from '../../reducers/User/types';
import { IAppState } from '../../types';
import { ActionTypes } from './types';

export const setUser = (user: User) => (dispatch: Dispatch<AnyAction>) => {
  const appUser: AppUser = {
    displayName: user.displayName ?? undefined,
    email: user.email ?? undefined,
    emailVerified: user.emailVerified,
    phoneNumber: user.phoneNumber ?? undefined,
    photoUrl: user.photoURL ?? undefined,
    uid: user.uid,
  };

  dispatch({
    type: ActionTypes.DO_LOGIN,
    payload: {
      user: appUser,
    },
  });
};

export const doLogin = (
  loginEmail: string,
  loginPassword: string,
  remember: boolean,
) => async (dispatch: ThunkDispatch<IAppState, void, AnyAction>): Promise<boolean> => {
  try {
    setPersistence(auth, remember ? browserLocalPersistence : browserSessionPersistence)
      .then(async () => {
        const { user } = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
        dispatch(setUser(user));
      })
      .catch((error: any) => {
        /*  */
      });
    return true;
  } catch (error) {
    return false;
  }
};

export const doLogout = async (dispatch: Dispatch<AnyAction>): Promise<void> => {
  dispatch({ type: ActionTypes.DO_LOGOUT });
};
