import styled from 'styled-components';
import { LoadingOutlined as AntdLoadingOutlined } from '@ant-design/icons';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #F0F2F5;
`;

export const LoadingOutlined = styled(AntdLoadingOutlined)`
  font-size: 35px;
`;
