import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IAppState } from '../store/types';

interface ProtectedRouteProps {

}

function ProtectedRoute(props: React.PropsWithChildren<ProtectedRouteProps>) {
  const { children } = props;
  
  const user = useSelector(
    (reduxState: IAppState) => reduxState.user.user,
  );

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      {children || <Outlet />}
    </>
  );
}

export default ProtectedRoute;
