import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import allRoutes from './routes';
import NotFound from '../pages/NotFound';
import ProtectedRoute from './ProtectedRoute';
import Loading from '../components/Loading';

function AppRouter() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          {Object.values(allRoutes).map((route) => (
            <Route
              key={+route}
              path={route.path}
              element={
                route.isProtected ? (
                  <ProtectedRoute>
                    <route.element />
                  </ProtectedRoute>
                ) : (
                  <route.element />
                )
              }
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default AppRouter;
