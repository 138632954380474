import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';

function NotFound() {
  return (
    <S.Container>
      <S.NumberStatus>
        404
      </S.NumberStatus>
      <S.TextContainer>
        <S.Text>OOps! Page not found</S.Text>
        <S.Text>The page you requested could not be found</S.Text>
        <Link to="/dashboard">
          <S.Button>Home</S.Button>
        </Link>
      </S.TextContainer>
    </S.Container>
  );
}

export default NotFound;
