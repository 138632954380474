import { Reducer } from '@reduxjs/toolkit';
import { ActionTypes, IUserState } from './types';

const INITIAL_STATE: IUserState = {
  user: undefined,
};

const userReducer: Reducer<IUserState> = (
  state = INITIAL_STATE,
  action,
): IUserState => {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.DO_LOGIN:
      return {
        ...state,
        user: payload.user,
      };
    case ActionTypes.DO_LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default userReducer;
