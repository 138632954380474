import { combineReducers, configureStore } from '@reduxjs/toolkit';

import userReducer from './reducers/User/index';

const reducers = combineReducers({
  user: userReducer,
});

const store = configureStore({
  reducer: reducers,
});

export type AppDispatch = typeof store.dispatch;

export default store;
