import React from 'react';
import { Container, LoadingOutlined } from './styles';

function Loading() {
  return (
    <Container>
      <LoadingOutlined spin />
    </Container>
  );
}

export default Loading;
