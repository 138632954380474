import React from 'react';

const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const ParkingActivity = React.lazy(() => import('../pages/ParkingActivity'));
const ParkedVehicle = React.lazy(() => import('../pages/ParkedVehicle'));
const ParkingAffiliation = React.lazy(() => import('../pages/ParkingAffiliation'));
const ParkingOccupancyHour = React.lazy(() => import('../pages/ParkingOccupancyHour'));
const ParkingGarage = React.lazy(() => import('../pages/ParkingGarage'));
const ParkingCounter = React.lazy(() => import('../pages/ParkingCounter'));
const Login = React.lazy(() => import('../pages/Login'));

export type AppRoute = {
  path: string;
  element: React.ComponentType;
  isProtected: boolean;
};

const routes: Record<string, AppRoute> = {
  Root: {
    path: '/',
    element: ParkingCounter,
    isProtected: true,
  },
  Login: {
    path: '/login',
    element: Login,
    isProtected: false,
  },
  Dashboard: {
    path: '/dashboard',
    element: Dashboard,
    isProtected: true,
  },
  ParkingActivity: {
    path: '/parking-activity',
    element: ParkingActivity,
    isProtected: true,
  },
  ParkedVehicle: {
    path: '/parked-vehicle',
    element: ParkedVehicle,
    isProtected: true,
  },
  ParkingAffiliation: {
    path: '/parking-affiliation',
    element: ParkingAffiliation,
    isProtected: true,
  },
  ParkingOccupancyHour: {
    path: '/parking-occupancy-hour',
    element: ParkingOccupancyHour,
    isProtected: true,
  },
  ParkingGarage: {
    path: '/parking-garage',
    element: ParkingGarage,
    isProtected: true,
  },
  ParkingCounter: {
    path: '/parking-counter',
    element: ParkingCounter,
    isProtected: true,
  },
};

export default routes;
