import React, { useEffect, useState, useCallback } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';

import { auth } from '../config/firebase-config';

import useAppDispatch from '../hooks/useAppDispatch';

import { setUser } from '../store/actions/User';

interface AuthenticationGateProps {

}

function AuthenticationGate(props: React.PropsWithChildren<AuthenticationGateProps>) {
  const {
    children,
  } = props;

  const [initializing, setInitializing] = useState(true);
  const dispatch = useAppDispatch();

  const onAuthState = useCallback((currentUser: User | null) => {
    if (currentUser) {
      dispatch(setUser(currentUser));
    }
    setInitializing(false);
  }, []);

  useEffect(() => {
    const sub = auth.onAuthStateChanged(onAuthStateChanged(auth, onAuthState));
    return sub;
  }, []);

  if (initializing) return null;

  return (
    <>
      {children}
    </>
  );
}

export default AuthenticationGate;
