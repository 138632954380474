export const ActionTypes = {
  DO_LOGIN: '@user/DO_LOGIN',
  DO_LOGOUT: '@user/DO_LOGOUT',
};

export interface AppUser {
  uid: string,
  email?: string,
  emailVerified: boolean,
  phoneNumber?: string,
  photoUrl?: string,
  displayName?: string,
}

export interface IUserState {
  user?: AppUser;
}
